/* Modal */
.ReactModalPortal > div {
	opacity: 0;
}

.ReactModalPortal .ReactModal__Overlay {
	align-items: center;
	display: flex;
	justify-content: center;
	transition: opacity 0.2s ease-in-out;
	z-index: 999;
	position: fixed;

	/* position: absolute; */
}

.autorizadosModal {
	position: fixed;
	background: white;
	border-radius: 5px;
	color: rgb(51, 51, 51);
	display: inline;
	max-height: 620px;
	max-width: 1500px;
	outline: none;
	padding: 10px;
	margin-top: 50px;
}

.modal-fondo {
	background-color: rgba(0, 0, 0, 0.3);
	bottom: 0;
	left: 0;
	right: 0;
	top: 0;

	/* position: fixed; */
}

.ReactModalPortal .ReactModal__Overlay--after-open {
	opacity: 1;
}

.ReactModalPortal .ReactModal__Overlay--before-close {
	opacity: 0;
}

.modal {
	background: white;
	border-radius: 5px;
	color: rgb(51, 51, 51);
	display: inline;
	max-height: 620px;
	max-width: 800px;
	outline: none;
	padding: 10px;
	margin-top: 50px;
}

.printModal {
	background: white;
	border-radius: 5px;
	color: rgb(51, 51, 51);
	display: inline;
	max-height: 480px;
	max-width: 1125px;
	outline: auto;
	padding: 10px;
	margin-top: 80px;
	font-size: 15px;
	position: absolute;
}

.printModalSanidad {
	background: white;
	border-radius: 5px;
	color: rgb(51, 51, 51);
	display: inline;
	max-height: 480px;
	height: auto;
	max-width: 1125px;
	outline: auto;
	padding: 10px;
	margin-top: 80px;
	font-size: 13px;
	position: absolute;
}

.printPermisoSubLogo {
	font-size: 10px;
	font-weight: bold;
	text-align: center;
}

.font12 {
	font-size: 12px;
}

.table-bordered > tbody > tr > td,
.table-bordered > tbody > tr > th,
.table-bordered > tfoot > tr > td,
.table-bordered > tfoot > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > thead > tr > th {
	border: 1px solid black;
}

p {
	font-size: 15px;
	text-align: justify;
	text-justify: inter-word;
}

.printModal h1 {
	font-size: 28px;
}
.printModal h2 {
	font-size: 18px;
}
.printModal h3 {
	font-size: 15px;
}

.printModal ul {
	list-style: none;
	text-decoration: none;
}

.text-dotacion {
	font-size: 14px;
}

.firma {
	margin-top: 50px;
}

/* Date picker */

.react-datetime-picker input:focus {
	outline: none;
}

.react-datetime-picker__wrapper {
	border: 0px solid gray !important;
}

.longCell {
	max-width: 300px !important;
}

.table {
	table-layout: auto;
	width: 100%;
}

.table td {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.moneda::before {
	content: "$";
}

.paragraph div {
	text-align: justify;
	text-justify: inter-word;
}

@page {
	margin-bottom: 4%;
	margin-top: 4%;
}

/* @media all {
	.page-break {
		display: none;
	}
} */

@media print {
	.no-printme {
		display: none !important;
	}

	.printme {
		display: block !important;
	}

	.header {
		padding-top: 0px !important;
	}

	.printModal {
		outline: none;
		max-height: 1500px;
		margin-top: 0px;
	}

	.printModalSanidad {
		outline: none;
		max-height: 1500px;
		margin-top: 0px;
	}

	.page-break {
		margin-top: 1rem;
		display: block;
		page-break-after: always;
	}
}

/* FABS */

.fab {
	border-radius: 100%;
	bottom: 25px;
	font-size: 30px;
	padding: 25px;
	position: fixed;
	right: 25px;
}

.fab-danger {
	bottom: 25px;
	padding: 10px;
	position: fixed;
	left: 25px;
}

.bgc {
	background-color: crimson;
}

.bgt {
	background-color: teal;
}

.header {
	padding-top: 90px;
}

.detallePermiso {
	font-size: 12px;
	background-color: #f2f2f2;
}

.watermark {
	transform: rotateY(45deg);
	font-size: 100px;
	font-weight: bold;
	margin: 25%;
	position: absolute;
	opacity: 0.2;
	z-index: 9999;
	color: red;
}

.rotar {
	-ms-transform: rotate(-45deg);
	-webkit-transform: rotate(-45deg);
	transform: rotate(-45deg);
}
